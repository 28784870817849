<template>
  <div>
    <a-page-header class="demo-page-header" style=" padding: 0;" title="Leave Approval">
    </a-page-header>

    <a-row class="my-20" justify="space-between" align="middle">
      <a-radio-group v-model:value="search">
        <a-radio-button value="UNAPPROVED">Unapproved</a-radio-button><a-radio-button
          value="APPROVED">Approved</a-radio-button>
        <a-radio-button value="DISAPPROVED">Disapproved</a-radio-button>

        <a-radio-button value="ALL">All</a-radio-button>
      </a-radio-group>
    </a-row>

    <a-table :dataSource="list" :columns="columns" :loading="loading" :pagination="{
      hideOnSinglePage: false,
      total: total,
      current: currentPage,
      defaultPageSize: limit,
    }" @change="tableChange">
      <template #user="{ text }">
        {{ text.name }}
      </template>
      <template #leaveType="{ text }">
        {{ text }}
      </template>
      <template #approved="{ text }">
        {{ text == null ? "Unapproved" : text ? "Approved" : "Disapproved" }}
      </template>
      <template #tool="{ record }">
        <a-dropdown :trigger="['click']">
          <a class="ant-dropdown-link" @click.prevent>
            <SettingOutlined />
          </a>
          <template #overlay>
            <a-menu>
              <a-menu-item @click="
                showModal = true;
              tobeshow = record;
              approve = record.approved;
              DatReasone = record.approvedReason;
                                ">
                Approval
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
      </template>
    </a-table>

    <a-modal v-model:visible="showModal" title="Approval" :maskClosable="false" :keyboard="false" centered
      @ok="showModal = false">
      <div class="  mb-20">
        <a-descriptions title="" :column="1" bordered>
          <a-descriptions-item label="User">{{
            tobeshow.user?.name
          }}</a-descriptions-item>
          <a-descriptions-item label="Date">{{ tobeshow.date }} {{ tobeshow.dayPart }}
          </a-descriptions-item>
          <a-descriptions-item label="Leave Type">{{ tobeshow.leaveType }}</a-descriptions-item>
          <a-descriptions-item label="Leave Reason">{{
            tobeshow.leaveReason
          }}</a-descriptions-item>
        </a-descriptions>
      </div>
      <div class="mb-20">
        <a-card>
          <a-row class="mb-10">
            <a-col :span="6">Approve</a-col>
            <a-col>
              <a-radio-group name="radioGroup" v-model:value="approve">
                <a-radio :value="true">Yes</a-radio>
                <a-radio :value="false">No</a-radio>
              </a-radio-group>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="6">Reason</a-col>
            <a-col :span="18">
              <a-textarea v-model:value="DatReasone" :auto-size="{ minRows: 5 }" allow-clear />
            </a-col>
          </a-row>
        </a-card>
      </div>
      <template #footer>
        <a-button key="submit" type="primary" :loading="loading" @click="submitapproved(tobeshow.id)"
          :disabled="typeof approve != 'boolean'">Submit
        </a-button>
      </template>
    </a-modal>
  </div>
</template>
<script>
import {
  reactive,
  toRefs,
  getCurrentInstance,
  watch,
  defineComponent,
} from "vue";

import {
  LeftOutlined,
  RightOutlined,
  SettingOutlined,
} from "@ant-design/icons-vue";

const columns = [
  {
    title: "User",
    dataIndex: "user",
    ellipsis: true,
    slots: {
      customRender: "user",
    },
  },
  {
    title: "Date",
    dataIndex: "date",
    ellipsis: true,
  },
  {
    title: "Leave Type",
    dataIndex: "leaveType",
    ellipsis: true,
    slots: {
      customRender: "leaveType",
    },
  },
  {
    title: "Leave Reason",
    dataIndex: "leaveReason",
    ellipsis: false,
    slots: {
      customRender: "reason",
    },
  },
  {
    title: "Approval",
    dataIndex: "approved",
    ellipsis: true,
    slots: {
      customRender: "approved",
    },
  },
  {
    title: "...",
    dataIndex: "height",
    slots: {
      customRender: "tool",
    },
  },
];

export default defineComponent({
  components: {
    LeftOutlined,
    RightOutlined,
    SettingOutlined,
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const v = reactive({
      type: null,
      baseInfo: null,
      loading: false,
      list: [],
      page: 1,
      limit: 10,
      total: 0,
      currentPage: 1,
      showModal: false,
      tobeshow: null,
      DatReasone: "",
      approve: null,
      search: "UNAPPROVED",
    });

    watch(
      () => v.search,
      () => {
        getlist();
      }
    );

    getlist();
    async function getlist() {
      let obj = { page: v.page, limit: v.limit, approved: v.search };
      v.loading = true;
      const res = await proxy.$axios.get("/api/v1/leaves/all", obj);
      v.loading = false;
      if (res.data) {
        v.list = res.data.items;
        v.total = res.data.total;
        v.list.forEach((item, i) => {
          item.key = i;
        });
      }
    }

    function tableChange(index) {
      v.page = index.current;
      v.currentPage = index.current;
      getlist();
    }

    function togglemonth(type) {
      if (type) {
        v.currentTime += 86400000 * 30;
      } else {
        v.currentTime = v.currentTime - 86400000 * 30;
      }
    }

    async function submitapproved(id) {
      let { approve, DatReasone: reason } = v;
      const res = await proxy.$axios.put(`/api/v1/leaves/${id}/approve`, {
        approve,
        reason,
      });
      if (res.status == 200) {
        proxy.$msg.success("sucmit success");
        v.tobeshow = { user: {}, leaveType: {} };
        v.showModal = false;
        v.approve = null;
        v.DatReasone = null;
        getlist();
      }
    }

    return {
      ...toRefs(v),
      tableChange,
      columns,
      togglemonth,
      submitapproved,
    };
  },
});
</script>
<style lang="scss" scoped>
.label {
  color: #666666;
  font-size: 16px;
  font-weight: 550;
}
</style>
